import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import InstagramLogo from "../assets/images/InstagramLogo.png";
import TikTokLogo from "../assets/images/TikTokLogo.png";

const Footer: React.FC = () => (
  <motion.footer
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    className="bg-flexerLightGray py-4 px-6 mt-8"
  >
    <div className="container mx-auto flex flex-col items-center space-y-2">
      <div className="space-x-4">
        <Link
          to="/privacypolicy"
          className="text-flexerRed hover:text-flexerDarkRed transition-colors duration-300 text-lg"
        >
          Privacy Policy
        </Link>
        <Link
          to="/termsofservice"
          className="text-flexerRed hover:text-flexerDarkRed transition-colors duration-300 text-lg"
        >
          Terms of Service
        </Link>
      </div>
      <div className="flex items-center space-x-4 mt-2">
        <motion.a
          whileHover={{ scale: 1.07 }}
          href="https://www.instagram.com/flexapp_/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={InstagramLogo}
            alt="Instagram"
            className="h-6 sm:h-7 md:h-9"
          />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.07 }}
          href="https://www.tiktok.com/@flexapp_"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={TikTokLogo}
            alt="TikTok"
            className="h-6 sm:h-7 md:h-9"
          />
        </motion.a>
      </div>
    </div>
  </motion.footer>
);

export default Footer;
